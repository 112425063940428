import { template as template_8fe88e8c11994a32aba6c2517011e230 } from "@ember/template-compiler";
const WelcomeHeader = template_8fe88e8c11994a32aba6c2517011e230(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
