import { template as template_d9f738821914423d88a1b2c038b5395a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9f738821914423d88a1b2c038b5395a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
