import { template as template_e5f3756286b2459e93d75826e06814c6 } from "@ember/template-compiler";
const FKText = template_e5f3756286b2459e93d75826e06814c6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
